.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}
